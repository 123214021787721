(function () {
  const submit = document.querySelector('.psi__submit-btn');
  const loading = document.querySelector('.psi__loading');
  const pageSpeed = document.querySelector('.psi__page-speed');
  const notFound = document.querySelector('.psi__not-found');
  const performanceData = document.querySelector('.psi__performance-data');
  const firstContentfulPaint = document.querySelector('.first-contentful-paint');
  const speedIndex = document.querySelector('.speed-index');
  const largestContentfulPaint = document.querySelector('.largest-contentful-paint');
  const interactive = document.querySelector('.time-to-interactive');
  const totalBlockingTime = document.querySelector('.total-blocking-time');
  const firstContentfulPaintNum = firstContentfulPaint.getElementsByTagName('p');
  const speedIndexNum = speedIndex.getElementsByTagName('p');
  const largestContentfulPaintNum = largestContentfulPaint.getElementsByTagName('p');
  const interactiveNum = interactive.getElementsByTagName('p');
  const totalBlockingTimeNum = totalBlockingTime.getElementsByTagName('p');
  const circle = document.querySelector('.psi__circle');
  const api = '/api/call_pagespeed_api';
  const urlInput = document.querySelector('.psi__form-input');
  const validation = document.querySelector('.psi__validation');
  const regex = new RegExp(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/);
  let device = 'sp';
  let psiDeta;
  let radioBoxes = document.getElementsByName('device');
  for (let i = 0; i < radioBoxes.length; i++) {
    radioBoxes[i].addEventListener('change', () => {
      device = radioBoxes[i].checked && radioBoxes[i].value === 'sp' ? 'sp' : 'pc';
      reflectData();
    });
  }
  const urlValidation = () => {
    return regex.test(urlInput.value);
  };
  submit.addEventListener('click', (e) => {
    e.preventDefault();
    pageSpeed.classList.add('display-none');
    notFound.classList.add('display-none');
    urlInput.classList.remove('invalid');
    if (!urlValidation()) {
      urlInput.classList.add('invalid');
      validation.classList.remove('display-none');
      return;
    }
    validation.classList.add('display-none');
    loading.classList.remove('display-none');
    fetch(api, {
      method: 'POST',
      body: JSON.stringify({ url: urlInput.value }),
    })
      .then((response) => response.json())
      .then((data) => {
        psiDeta = data;
        if (data.status === 'success') {
          reflectData();
          loading.classList.add('display-none');
          pageSpeed.classList.remove('display-none');
        } else {
          loading.classList.add('display-none');
          notFound.classList.remove('display-none');
        }
      })
      .catch(() => {
        loading.classList.add('display-none');
        notFound.classList.remove('display-none');
      });
  });
  const organizePsiIndex = (n) => {
    n.split('');
    return n.length === 1 ? parseFloat(n).toFixed(1) : n;
  };
  const addEvaluationClass = (className, evaluation) => {
    className.classList.remove('slow');
    className.classList.remove('moderate');
    className.classList.remove('fast');
    if (evaluation === 'SLOW') className.classList.add('slow');
    if (evaluation === 'MODERATE') className.classList.add('moderate');
    if (evaluation === 'FAST') className.classList.add('fast');
  };
  const reflectIndexClass = (psiIndex, evaluation) => {
    if (psiIndex === 'firstContentfulPaint') psiIndex = firstContentfulPaint;
    if (psiIndex === 'speedIndex') psiIndex = speedIndex;
    if (psiIndex === 'largestContentfulPaint') psiIndex = largestContentfulPaint;
    if (psiIndex === 'interactive') psiIndex = interactive;
    if (psiIndex === 'totalBlockingTime') psiIndex = totalBlockingTime;
    addEvaluationClass(psiIndex, evaluation);
  };
  const reflectData = () => {
    const data = device === 'sp' ? psiDeta.data.mobile : psiDeta.data.desktop;
    circle.animate([{ strokeDashoffset: 697.08 }, { strokeDashoffset: 697.08 * (1 - data.performance.score) }], {
      fill: 'forwards',
      duration: 1000,
      easing: 'ease-in',
    });
    const propertyArray = Object.entries(data);
    propertyArray.forEach(function (element) {
      if (element[0] === 'performance') {
        addEvaluationClass(circle, element[1].evaluation);
        addEvaluationClass(performanceData, element[1].evaluation);
      } else {
        reflectIndexClass(element[0], element[1].evaluation);
      }
    });
    const firstContentfulPaintDara = data.firstContentfulPaint.displayValue.split(/\s/);
    const speedIndexDara = data.speedIndex.displayValue.split(/\s/);
    const largestContentfulPaintDara = data.largestContentfulPaint.displayValue.split(/\s/);
    const interactiveDara = data.interactive.displayValue.split(/\s/);
    const totalBlockingTimeDara = data.totalBlockingTime.displayValue.split(/\s/);
    performanceData.innerHTML = Math.floor(data.performance.score * 100);
    firstContentfulPaintNum[0].innerHTML = organizePsiIndex(firstContentfulPaintDara[0]);
    speedIndexNum[0].innerHTML = organizePsiIndex(speedIndexDara[0]);
    largestContentfulPaintNum[0].innerHTML = organizePsiIndex(largestContentfulPaintDara[0]);
    interactiveNum[0].innerHTML = organizePsiIndex(interactiveDara[0]);
    totalBlockingTimeNum[0].innerHTML = organizePsiIndex(totalBlockingTimeDara[0]);
  };
})();
